import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LinksPage from "./pages/LinksPage";
import TranslatorPage from "./pages/TranslatorPage";
import {WebStorageStateStore} from "oidc-client-ts";
import {AuthProvider} from "react-oidc-context";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App content={<LinksPage/>} menu={"internal_links"} header={'abp_hub.png'} />,
    },
    {
        path: "translator",
        element: <App content={<TranslatorPage/>} menu={"translator"} header={'abp_translator.png'} />,
    },
    {
        path: "internal_links",
        element: <App content={<LinksPage/>} menu={"internal_links"} header={'abp_hub.png'} />,
    },
]);

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}

const keycloakUrl =  process.env.REACT_APP_KEYCLOAK_URL
const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID

const oidcConfig = {
    authority: keycloakUrl + "realms/abp-hub",
    redirect_uri: window.location.href,
    client_id: keycloakClientId,
    onSigninCallback: onSigninCallback,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

ReactDOM.createRoot(document.getElementById('root')).render(
    <>
        <AuthProvider {...oidcConfig}>
            <RouterProvider router={router} />
        </AuthProvider>
    </>
);
