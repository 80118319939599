import React from "react";

import LinkCard from "../components/links/LinkCard";
import { useTranslation } from 'react-i18next';
import {Carousel, Col, Collapse, Row, FloatButton } from "antd";
import { CommentOutlined, CustomerServiceOutlined, MailOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

function LinksPage() {

    const { t, i18n } = useTranslation();

    const contentStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    };

    const bannerStyle = {
        maxHeight: "455px"
    }

    const slides = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];

    const handleMailClick = () => {
        const to = "sebastian.hordt@abpinduction.com;";
        const cc = "robin.czarnetzki@abpinduction.com;albert.miller@abpinduction.com;stefan.andorf@abpinduction.com;mick.ruppert@abpinduction.com";
        const subject = "ABPHub";
        window.location.href = `mailto:${to}?cc=${cc}&subject=${encodeURIComponent(subject)}`;
    };

    return (
        <div style={{paddingBottom: 80}}>
            <Row>
                <Col
                    xs={{
                        span: 24,
                    }}
                    xxl={{
                        span: 12,
                    }}
                >
                    <Carousel dotPosition={"top"} autoplay arrows autoplaySpeed={7000}>
                        {
                            slides.map((slide) =>
                                <div>
                                    <div style={contentStyle}>
                                        <img style={bannerStyle} src={"/img/carousel/Folie" + slide + ".PNG"}
                                             alt={"Demo"}/>
                                    </div>
                                </div>
                            )
                        }
                    </Carousel>
                </Col>
                <Col
                    xs={{
                        span: 24,
                    }}
                    xxl={{
                        span: 12,
                    }}
                >
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        style={{
                            marginBottom: 25
                        }}
                    >
                        <CollapsePanel
                            header="ABP Induction Group"
                            key="1"
                        >
                            <div style={{display: "flex", flexWrap: "wrap"}}>
                                <LinkCard name="ABP Induction" link="https://abpinduction.com" logo="abp2.png"
                                          description="abp"
                                          internal={false}/>
                                <LinkCard name="CWA" link="https://abp.cwaflow.net/smartprocess" logo="cwa2.png"
                                          description="cwa"
                                          internal={false}/>
                                <LinkCard name="Eplas" link="https://abp.eplas.net/" logo="eplas.png"
                                          description="eplas"
                                          internal={false}/>
                                <LinkCard name="E-Learning" link="https://elearning.abpinduction.com/"
                                          logo="eplatform3.png"
                                          description="elearning" internal={false}/>
                                <LinkCard name="Q.Wiki" link="https://abpinduction.qwikinow.de/" logo="qwiki.png"
                                          description="qwiki"
                                          internal={false}/>
                                <LinkCard name="Salesforce" link="https://abp-induction.my.salesforce.com/one/one.app"
                                          logo="salesforce.png" description="salesforce" internal={false}/>
                                <LinkCard name="Translator" link="/translator" logo="translator3.png"
                                          description="translator" internal={true}/>
                            </div>
                        </CollapsePanel>
                    </Collapse>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                    >
                        <CollapsePanel
                            header="ABP Induction Germany"
                            key="1"
                        >
                            <div style={{display: "flex", flexWrap: "wrap"}}>
                                <LinkCard name="Interflex" link="https://denrdoas15.pillar.com/eVAYO"
                                          logo="interflex.png"
                                          description="interflex" internal={false}/>
                                <LinkCard name="Onesto" link="https://www.onesto.de/abp" logo="onesto.png"
                                          description="onesto"
                                          internal={false}/>
                                <LinkCard name="Rydoo" link="https://app.rydoo.com/" logo="rydoo2.png"
                                          description="rydoo"
                                          internal={false}/>
                                <LinkCard name="User Helpdesk" link="https://abpinduction.qwikinow.de/Germany/Processes/UserHelpdesk?title=user-helpdesk" logo="help-desk.png"
                                          description="helpdesk"
                                          internal={false}/>
                            </div>
                        </CollapsePanel>
                    </Collapse>
                </Col>
            </Row>
            <FloatButton.Group
                trigger="click"
                style={{
                    right: 24,
                    bottom: 62,
                }}
                icon={<QuestionCircleOutlined/>}
            >
                <FloatButton
                    icon={<MailOutlined/>}
                    onClick={handleMailClick}
                />
            </FloatButton.Group>
            {/*<embed src="https://abpinduction.com/wp-content/uploads/2024/07/ABP-News-20-July-2024-EN.pdf" width="1000" height="500" type="application/pdf"/>*/}
        </div>
    );
}

export default LinksPage;